import React from 'react'
import { useNavigate } from 'react-router-dom';

const Badge = ({item,picture}) => {
  const navigate= useNavigate()

const toContactUs = () =>{

  navigate('/contact');
console.log("Product selected");
}

  return (
    <>
    <div className='w-72 h-80 border-2 border-gray-100 rounded-lg shadow-xl mx-auto mt-12'>
        <div className='mx-auto text-xl pt-5 w-36 h-20 text-center text-gray-700'>{item}</div>
        <div className='w-48  mx-auto border-2 border-gray-100'>
            <img className='h-32 mx-auto' src= {picture} alt='img' />
        </div>
        <div className='w-32 h-10 mt-5 border-2 border-[#005E9C] mx-auto rounded-3xl text-lg text-[#005E9C] pt-1 hover:cursor-pointer' onClick={toContactUs}>View More

        </div>
    
    </div>
    </>
  )
}

export default Badge