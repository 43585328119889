import React from 'react'
import Industries from './Industries'

const Business = () => {
  return (
   <>
   <div>
  <div  className='text-3xl font-semibold'>Industries We Serve</div>
  <div className='flex flex-wrap mx-auto'>
  <Industries name={'Cement Industries'} image={'./industries/cement.jpg'} />
  <Industries name={'Chemical Industries'} image={'./industries/chemi.jpg'} /> 
  <Industries name={'Construction'} image={'./industries/construction.jpg'} />
  <Industries name={'Fertilizer Industries'} image={'./industries/ferti.jpg'} /> 
  <Industries name={'Pharmacy'} image={'./industries/pharma.jpg'} /> 
  </div> 
    </div>
   </>
  )
}

export default Business