import React from 'react'
import MainRouter from './MainRouter'

const App = () => {
  return (
    <>
      <MainRouter />   
    </>    
  )
}

export default App