import React, { useEffect, useState } from 'react'
import axios from 'axios'


  const Contact = () => {
  const[inputName,setInputName] = useState('')
  const[inputMail,setInputMail] = useState('')
  const[inputNumber,setInputNumber] = useState('')
  //const[inputSubject,setInputSubject] = useState('')
  const[inputMessage,setInputMessage] = useState('')
  const[errorCheck1,setErrorCheck1] = useState(true)
  const[errorCheck2,setErrorCheck2] = useState(true)
  const[errorCheck3,setErrorCheck3] = useState(true)
  const[errorCheck4,setErrorCheck4] = useState(true)
  const[showResponse,setShowResponse] = useState('')
  const[displayMessage,showDisplayMessage] = useState(false)
  const[backgroundColor,setBackgroundColor] = useState('')
  const inputSubject = "Customer Enquiry"

    useEffect(()=>{
    setErrorCheck1(inputName.trim() !== '');
    setErrorCheck2(inputMail.trim() !== '');
    setErrorCheck3(inputNumber.trim() !== ''); 
    setErrorCheck4(inputNumber.trim() !== '');

      const timerSet = () =>{
      setTimeout(()=>{
      //console.log("entered into Timer");
      if(backgroundColor==='green'){
      setInputName('')
      setInputMail('')
      setInputNumber('')
      //setInputSubject('')
      setInputMessage('')  
      }      
      showDisplayMessage(false)
      },3000)
      }
      
      if(displayMessage){
      timerSet()
      }  


    },[inputName,inputMail,inputNumber,displayMessage])

    const handleSubmit = async (e) =>{
    e.preventDefault(); 
    

          if(errorCheck1 && errorCheck4){
          //showDisplayMessage(false)//https://www.blrsafetysolutions.com/path-to/sendEmail.php
          try{
          const response = await axios.post('https://blrsafetysolutions.com/sendEmail.php',{
          inputName,
          inputSubject,
          inputMail,
          inputNumber,
          inputMessage
          })
          
          const receivedData = response.data;          
          setBackgroundColor('green')
          showDisplayMessage(true)
          setShowResponse(receivedData.message)
          } 
          catch(error){
           // setShowResponse(receivedData.message)  
          const errorFetch = error
          //console.error('Error submitting form:',errorFetch.message);
          setBackgroundColor('red')
          showDisplayMessage(true)
          setShowResponse(errorFetch.message)
          } 
        
          }
    
  }
  
  return (
    <>
<div className='w-[100%]'>
<form onSubmit={handleSubmit}>
<div className='lg:pt-24 md:pt-24 sm:pt-20 xs:pt-20 lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold pl-4'>Contact Us</div>
<div className='lg:text-xl md:text-xl sm:text-lg xs:text-lg text-gray-500 lg:mt-4 md:mt-4 sm:mt-2 xs:mt-2 pl-4'>Name</div>
<div className='flex flex-row'>
<div className='pl-4'>
<input type='text' className=' lg:w-96 md:w-80 sm:w-64 xs:w-64 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500'  value={inputName} onChange={(e)=>setInputName(e.target.value)}/>
</div>
<div>
{errorCheck1?  null : (<div className='pl-2 lg:text-base md:text-base sm:text-sm xs:text-sm text-red-600 '>This Field is Required</div>) }
</div>
</div>


<div className='text-xl text-gray-500 lg:mt-4 pl-4'>Phone Number</div>
<div className='flex flex-row'>
<div  className='pl-4'>
<input type='text' className=' lg:w-96 md:w-80 sm:w-64 xs:w-64 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500'  value={inputNumber} onChange={(e)=>setInputNumber(e.target.value)}/>
</div>
<div>
{errorCheck4?  null : (<div className='pl-2 lg:text-base md:text-base sm:text-sm xs:text-sm text-red-600 '>This Field is Required</div>) }
</div>
</div>



<div className='text-xl text-gray-500 lg:mt-4 pl-4'>Email Id</div>

<div className='flex flex-row'>
<div  className='pl-4'>
<input type='text' className=' lg:w-96 md:w-80 sm:w-64 xs:w-64 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500'  value={inputMail} onChange={(e)=>setInputMail(e.target.value)}/>
</div>
</div>




<div className='text-lg text-gray-500 lg:mt-4 pl-4'>Type Your Message here</div>

<div className='flex flex-col'>
      <textarea
      className="border border-gray-300 rounded-md px-4 py-2 ml-4 focus:outline-none focus:border-blue-500 lg:w-96 md:w-96 sm:w-64 xs:w-64 h-28 "
      placeholder="Enter message here..." value={inputMessage}  onChange={(e)=>setInputMessage(e.target.value)}/>

      <div className=' flex flex-row'>
      <button type='submit' className='sm:ml-24 xs:ml-28 w-32 h-10  lg:mt-12 md:mt-12 sm:mt-5 xs:mt-5 border-2 border-[#005E9C] lg:ml-8 font-semibold  rounded-3xl text-lg text-[#005E9C] pt-0' id='submitted'> 
      Submit</button>
  

      
      <div className = {`transition-opacity duration-1000 ${displayMessage ? 'opacity-100' : 'opacity-0'}`}>
      <div style={{ backgroundColor }} className= 'w-full h-10 pl-4 pt-2 lg:mt-12 md:mt-12 sm:mt-5 xs:mt-5 ml-4 border rounded-md text-white'>{showResponse}</div>

      </div>


     </div>
</div>

<div className='w-[100%] lg:h-24 md:h-24 sm:h-24 xs:h-24 bg-black text-gray-700 pt-2 pl-4 lg:mt-20 md:mt-72 sm:mt-4 xs:mt-4 tracking-wide'>BLR Safety Solutions Pvt.Ltd. CopyRight 2024</div>

</form> 
</div>
    </>
  )
}

export default Contact

