import React from 'react'

const NotFound = () => {
  return (
    <>
    <div className='w-56 h-28'></div>
    <div className='text-3xl text-red-800'>Page Not Found</div>
    </>
  )
}

export default NotFound