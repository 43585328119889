import React, { useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Badge from './Badge';

const Product = () => {

  useEffect(()=>{
  window.scrollTo(0, 0);
  })
  return (
    <>
<div className='w-full h-128 bg-white text-center text-4xl pt-24 font-bold text-gray-800'>
<div>Our Products</div>

      <div className='w-full flex flex-wrap'>  
          <Badge item='Fire Extingusher' picture={process.env.PUBLIC_URL + 'images/fireext.jpg'} />
          <Badge item='Eye Protection' picture={process.env.PUBLIC_URL + 'images/eyeprotect.jpg'} />
          <Badge item='Face Protection' picture={process.env.PUBLIC_URL + 'images/faceprotect.jpg'} />
          <Badge item='Hand Protection' picture={process.env.PUBLIC_URL + 'images/gloves.jpg'} />
          <Badge item='Body Protection' picture={process.env.PUBLIC_URL + 'images/bodyprotect.jpg'} />
          <Badge item='Respiratory Protection' picture={process.env.PUBLIC_URL + 'images/mask.jpg'} />
          <Badge item='Safety Harness Belt' picture={process.env.PUBLIC_URL + 'images/safetybelt.jpg'} />
          <Badge item='Reflective Wear' picture={process.env.PUBLIC_URL + 'images/reflectivewear.jpg'} />
          <Badge item='Head Protection' picture={process.env.PUBLIC_URL + 'images/helmet.jpeg'} />
          <Badge item='Ear Protection' picture={process.env.PUBLIC_URL + 'images/earcap.jpg'} />
          <Badge item='Traffic Control' picture={process.env.PUBLIC_URL + 'images/safetycone.jpg'} />
          <Badge item='Foot Protection' picture={process.env.PUBLIC_URL + 'images/shoes.jpg'} />

          </div>
          <NavLink exact to='/contact' className='lg:px-5 md:px-5  ' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>
          <div className='w-36 h-14 mt-10 border-2 border-[#005E9C] mx-auto rounded-3xl text-xl text-[#005E9C] pt-3'>Know More
          </div>  
          </NavLink> 


      <Link to={'product'}><div className=' px-5 text-white pt-2'>PRODUCT</div></Link>







  </div>
  <div className='w-[100%] h-24 bg-black text-gray-700 pt-2 pl-4 lg:mt-20 md:mt-72 sm:mt-4 xs:mt-4 text-sm tracking-wide'>BLR Safety Solutions Pvt.Ltd. CopyRight 2024</div>   
    </>
    
  )
}

export default Product