import React, { useEffect, useState} from 'react'
import { NavLink, Link } from 'react-router-dom';
import Badge from './Badge';
import Industries from './Industries';
import { MdMailOutline } from "react-icons/md";


const Home = () => { 

const imgArr = [process.env.PUBLIC_URL + './images/safety4.webp',
                process.env.PUBLIC_URL + '/images/safety2.webp',
                process.env.PUBLIC_URL + '/images/safety3.webp'] 

      const[currentVisible,setVisible] = useState(Array(3).fill(true)) //currentVisible Array will hold boolean value to switch the image
      const[currentScroll,setScroll] = useState(false)
      const[switchValue,setSwitchValue] = useState(0)
      const mailIcon = <MdMailOutline size={45} color="#ffffff" />
     
      
      useEffect(()=>{

      const handleWindowY = ()=>{        

      if(window.scrollY>=100 && window.scrollY<=200) {
      setScroll(true)
       console.log("100 pixels"); 
      }
      }

        window.addEventListener('scroll',handleWindowY);     
        const imgTimer = setInterval(()=>{ 
                 
        switch(switchValue){           
        case 0: {
        const switchArray = [...currentVisible]
        switchArray[0] = false
        switchArray[1] = false
        switchArray[2] = true
        setVisible(switchArray)              
        break;
      }
        case 1: {
        const switchArray = [...currentVisible]
        switchArray[0] = false
        switchArray[1] = true
        switchArray[2] = true
        setVisible(switchArray)
        break;
        }
        default :{
        const switchArray = [...currentVisible]
        switchArray[0] = true
        switchArray[1] = true
        switchArray[2] = true
        setVisible(switchArray)
              
      }
    }        
   
        setSwitchValue(switchValue + 1)       
        if(switchValue>=2){       
        setSwitchValue(0)
        } 
        },3000)

        return()=>{
        clearInterval(imgTimer) 
        window.removeEventListener('scroll',handleWindowY)
        }  
        },[currentVisible])



  return (
    <>
    <div> 
    
  <div className='relative'>
    <div className='xl:w-full lg:w-full md:w-full sm:w-full xs:w-full xl:h-96 lg:h-96 md:h-72 sm:h-60 xs:h-32 xl:mt-20 lg:mt-20 md:mt-20 sm:mt-20 xs:mt-20'>
      <img className = {`absolute transition-opacity duration-1000 ${currentVisible[2] ? 'opacity-100' : 'opacity-0'}`} src={imgArr[2]} alt='image2'/>
      <img className = {`absolute transition-opacity duration-1000 ${currentVisible[1] ? 'opacity-100' : 'opacity-0'}`} src={imgArr[1]} alt='image1'/>
      <img className = {`absolute transition-opacity duration-1000 ${currentVisible[0] ? 'opacity-100' : 'opacity-0'}`} src={imgArr[0]} alt='image0'/>
    </div>


      <div className='absolute flex flex-row xl:w-full lg:w-full md:w-full sm:w-full xs:w-full xl:h-48 lg:h-48 md:h-56 sm:h-40 xs:h-36 xl:mt-20 lg:mt-4 md:mt-0 sm:mt-0 xs:mt-0 bg-[#005E9C] z-40'>
      <NavLink exact to='contact' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>  
      <div className={`lg:pt-20 md:pt-20 sm:pt-14 xs:pt-14 text-white transition-transform duration-500 inline-block ${currentScroll ? 'lg:translate-x-full md:translate-x-1/2 sm:translate-x-1/2 xs:translate-x-1/4' : 'translate-x-0' } absolute transition-opacity duration-1000 ${currentScroll ? 'opacity-100' : 'opacity-0'}`} >
          
            
              <div className='flex flex-row'>         
              <div className='lg:px-2 lg:pt-1 md:pt-1 sm:pt-1 xs:pt-1 md:px-2 sm:px-1 xs:px-1'>{mailIcon}</div>
              <div className='lg:text-5xl md:text-5xl sm:text-4xl xs:text-3xl lg:pt-0 md:pt-0 sm:pt-2 xs:pt-2'>Get a Quote Now
              </div>
              </div>  
                     
                  
      </div>
      </NavLink>  

    </div>
    <div className='xl:w-full lg:w-full md:w-full sm:w-full xs:w-full xl:h-80 lg:h-80 md:h-64 sm:h-44 xs:h-36'>
      
    </div>
    </div>


<div className='w-full h-128 bg-white text-center text-4xl pt-8 font-bold text-gray-800'>
<div>Our Products</div>

      <div className='w-full flex flex-wrap'>  
          <Badge item='Fire Extingusher' picture={process.env.PUBLIC_URL + 'images/fireext.jpg'} />
          <Badge item='Eye Protection' picture={process.env.PUBLIC_URL + 'images/eyeprotect.jpg'} />
          <Badge item='Face Protection' picture={process.env.PUBLIC_URL + 'images/faceprotect.jpg'} />
          <Badge item='Hand Protection' picture={process.env.PUBLIC_URL + 'images/gloves.jpg'} />
          <Badge item='Body Protection' picture={process.env.PUBLIC_URL + 'images/bodyprotect.jpg'} />
          <Badge item='Respiratory Protection' picture={process.env.PUBLIC_URL + 'images/mask.jpg'} />
          <Badge item='Safety Harness Belt' picture={process.env.PUBLIC_URL + 'images/safetybelt.jpg'} />
          <Badge item='Reflective Wear' picture={process.env.PUBLIC_URL + 'images/reflectivewear.jpg'} />
      </div>
      <NavLink exact to='product' className='lg:px-5 md:px-5  ' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>
      <div className='w-36 h-14 mt-10 border-2 border-[#005E9C] mx-auto rounded-3xl text-xl text-[#005E9C] pt-3'>View More
      </div> 
      </NavLink>    

  </div>
  <div className='w-full mx-auto text-center pt-12'>
  <div  className='text-3xl font-semibold'>Industries We Serve</div>
  <div className='flex flex-wrap mx-auto'>
  <Industries name={'Cement Industries'} image={'./industries/cement.jpg'} />
  <Industries name={'Chemical Industries'} image={'./industries/chemi.jpg'} /> 
  <Industries name={'Construction'} image={'./industries/construction.jpg'} />
  <Industries name={'Fertilizer Industries'} image={'./industries/ferti.jpg'} /> 
  <Industries name={'Pharmacy'} image={'./industries/pharma.jpg'} /> 
    </div>  
 
  </div>
<div className='lg:mt-8 md:mt-8 sm:mt-6 xs:mt-6  lg:ml-10 md:ml-10 sm:mx-ato xs:mx-auto sm:px-4 xs:px-4'>
 <div  className='text-3xl font-semibold'>About Us</div> 


      <div className='w-[100%] flex flex-wrap lg:mt-2 md:mt-2 sm:mt-0 xs:mt-0'>
      <div className='lg:w-[40%] md:w-[40%] sm:w-[100%] xs:w-[100%] h-96 text-justify font-thin text-gray-400'>

      Established in 2016, BLR Safety Solutions specializes in distributing Industrial Safety Products, gaining a 
      reputable name in the industry for our high-quality range, including Safety Helmets, Safety Goggles, and Safety 
      Respirators,personal protective equipment (PPE), fall protection systems, respiratory protection, fire safety equipment, 
      and more. 
      <br/>
      <br/>
      These products boast high tensile strength, resistance to wear and tear, precise fitting, seamless finish, comfort, 
      lightweight design, and comprehensive safety for workers in various settings such as traffic, construction sites, 
      chemical plants, oil refineries, welding industries, and pesticide plants. All our products adhere to the highest 
      industry standards and are certified by reputable organizations such as OSHA and EN.

      <NavLink exact to='about' className='lg:px-5 md:px-5  ' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>
      <div className='w-36 h-14  border-2 border-[#005E9C] rounded-3xl text-xl font-semibold text-[#005E9C] pt-3 pl-5 mt-4 lg:ml-40 md:ml-40 sm:mx-auto xs:mx-auto'>Read More
      </div>
      </NavLink> 
      </div>
    <div className='lg:w-[40%] md:w-[40%] sm:w-[100%] xs:w-[100%] lg:ml-24 md:ml-24 lg:mt-0 md:mt-0 sm:mt-20 xs:mt-40 sm:px-auto xs:px-auto'>
      <img src='./industries/safety.jpg' alt='image' />
    </div>

 </div>  
 

</div>

<div className='w-[100%] h-24 bg-black text-gray-700 pt-2 pl-4 lg:mt-28 md:mt-72 sm:mt-4 xs:mt-4 tracking-wide'>BLR Safety Solutions Pvt.Ltd. CopyRight 2024</div>
</div>
    </>
    
  )
}

export default Home