import React, { useEffect, useState } from 'react'
import { FiMenu } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { NavLink, Link } from 'react-router-dom';
import './navbar.css'

const menuIcon = <FiMenu size={30} color="#005E9C" />
const closeIcon = <IoCloseSharp size={30} color="#005E9C" />

//let iconSwitch = true
const Navbar = () => {
const[navBar,setNavBar] = useState(true)    //Hook used to render NavBar
const[menuLine,setmenuLine] = useState(false)//Hook used to render the menu icon
const[menuBar,setMenuBar] = useState(true)
const[iconSwitch,setIconSwitch] = useState(true)

useEffect(()=>{
const handleScroll = ()=>{
setmenuLine(true)
setMenuBar(true)
}
  
    if(iconSwitch){                           
    if(window.innerWidth<=775){// This section renders initially to check the screen size when the program starts
    setNavBar(false)
    setmenuLine(true)
    }
    else{
    setNavBar(true)
    setmenuLine(false)
    } 
    setIconSwitch(false)                      //This variable will get false and this section wont repeat after the initial render
    }
  
      const WindowCheck=()=>{                 //This is a callBack function which renders when the screen size changed  
      if(window.innerWidth<=775){
      setNavBar(false)                    //setNavBar Check for the screen size and hides the component name HOME, PRODUCTS etc
      setmenuLine(true)                   //setMenuLine will make the menu icon visible when screen size lessthan 775 pixels
      }
      else{
      setNavBar(true)
      setmenuLine(false)
      setMenuBar(true)     
      }
      }
  window.addEventListener('resize',WindowCheck)
  window.addEventListener('scroll',handleScroll)
  return ()=>{

    // if(counter>10){
    //   setCounter(0)
    //     clearInterval(menuTimer)
    // }
    

  window.removeEventListener('resize',WindowCheck)
  window.removeEventListener('scroll',handleScroll)
  }
  },[navBar])

  const menuDissappear = () =>{
    setmenuLine(true)
    setMenuBar(true)
  }

  const menuIconShift = ()=>{             //This function will switch menuicon 
  setmenuLine(!menuLine)                  //Hook to switch the icon
  setMenuBar(!menuBar)                    //Hook to switch the vertical menu bar for small screen   
  }


  return (

    <div>

    <div className='top-0 fixed lg:w-full md:w-full lg: sm:w-full xs:w-full h-20 md:h-20 sm:h-20 xs:h-20 z-50 bg-white'>
     {(navBar? (<div className=' text-black lg:flex lg:flex-row lg:justify-between md:flex md:flex-row md:justify-between'>
    
                      
                              <div className='w-72 ml-2'>
                                  <img src={process.env.PUBLIC_URL + 'images/logo.png'} alt="Logo" />
                              </div>   

                              <div className='lg:flex lg:flex-row md:flex md:flex-row lg:pt-6 md:pt-6 text-lg lg:pr-8'>
                                <NavLink exact to='/' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>Home</NavLink>                                  
                                <NavLink exact to='product' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>Product</NavLink> 
                                <NavLink exact to='business' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>Business Sectors</NavLink>  
                                <NavLink exact to='about' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>About Us</NavLink>                                 
                                <NavLink exact to='contact' className='lg:px-5 md:px-5' style={({ isActive }) => ({color: isActive? "#005E9C": "#00171f"})}>Contact Us</NavLink>                             
                              </div> 
                     
                </div> )  
  

              :(<div className='w-full h-20 bg-white flex flex-row justify-between '>      
              <div className='w-60'>
                  <img src={process.env.PUBLIC_URL + 'images/logo.png'} alt="Logo" />
              </div> 

              <div className='p-6' onClick={menuIconShift}>
                  {(menuLine ? menuIcon : closeIcon)} 
              </div>
          
              </div>   
              ))} 

      {(menuBar ? null : (<div className=' relative text-lg left-0 z-50 mt-0 opacity-80'>
      <div className='bg-[#005E9C] absolute top-0 w-72 h-10 border-b border-white'><Link to={'/'}><div className=' px-5 text-white pt-2'onClick={menuDissappear}>HOME</div></Link></div>
      <div style={{top:'38px'}} className='bg-[#005E9C] absolute  w-72 h-10 border-b border-white mt-0.5'><Link to={'product'}><div className=' px-5 text-white pt-2'onClick={menuDissappear}>PRODUCT</div></Link></div> 
      <div style={{top:'78px'}} className='bg-[#005E9C] absolute  w-72 h-10 border-b border-white mt-0.5'><Link to={'business'}><div className=' px-5 text-white pt-2'onClick={menuDissappear}>BUSINESS SECTORS</div></Link></div> 
      <div style={{top:'118px'}} className='bg-[#005E9C] absolute  w-72 h-10 border-b border-white mt-0.5'><Link to={'about'}><div className=' px-5 text-white pt-2'onClick={menuDissappear}>ABOUT US</div></Link></div>  
      <div style={{top:'158px'}} className='bg-[#005E9C] absolute  w-72 h-10 border-b border-white mt-0.5'><Link to={'contact'}><div className=' px-5 text-white pt-2'onClick={menuDissappear}>CONTACT US</div></Link></div> 
      </div>)
      )}
      </div>
      </div>
      
      ) 
}     


export default Navbar